import { useState, useEffect, useContext } from "react";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Box, Button, Card, Chip, Grid, Typography } from "@mui/material";
import DozerCard from "examples/Cards/DozerCard";
import SoftAvatar from "components/SoftAvatar";
import { NavLink } from "react-router-dom";

import { getAssets } from "data/api/asset";
import { DozerContext, DozerDispatchContext } from "context/dozer-context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import colors from "assets/theme/base/colors";
import typography from "../../assets/theme/base/typography";
import moment from "moment";

const Assets = () => {
  const dozerDispatch = useContext(DozerDispatchContext);
  const dozerContext = useContext(DozerContext);
  const { user: dozerUser } = dozerContext;

  const [assets, setAssets] = useState(null);

  // call API for assets.
  const fetchAssets = async () => {
    const { organizationId } = dozerUser;

    if (organizationId) {
      let assetsResponse = null;
      try {
        assetsResponse = await getAssets(organizationId);
      } catch (error) {
        console.log("error fetching assets", error);
      }

      if (assetsResponse?.assets?.length) {
        dozerDispatch({
          type: "saveAssets",
          payload: assetsResponse.assets,
        });
        setAssets(assetsResponse.assets);
      }
    }
  };

  useEffect(() => {
    if (dozerUser) {
      fetchAssets();
    }
  }, []);

  const getActiveTime = (activeWindows) => {
    // Check if first is started. If not, add a start event at 0h today.
    // iterate over events, making sure we switch between start/stop events. Maintain sum of duration
    let duration = activeWindows.reduce((previousValue, currentValue) => {
      if (!currentValue.stopTimestamp || !currentValue.startTimestamp) {
        return previousValue;
      }

      return previousValue + (currentValue.stopTimestamp - currentValue.startTimestamp);
    }, 0);

    return `${Math.round(Math.abs(moment.duration(duration, "millisecond").asHours()))}h`;
  };

  return (
    <DashboardLayout pageTitle="Assets">
      <SoftBox py={3} sx={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <SoftBox mb={3} p={1} sx={{ height: "auto" }}>
              <Grid container sx={{ height: "auto" }} spacing={4}>
                {assets?.map((asset) => (
                  <Grid
                    item
                    rowSpacing={{ lg: 8, sm: 8, xs: 8 }}
                    xs={12}
                    sm={6}
                    lg={3}
                    xl={3}
                    key={asset.asset?.assetId}
                  >
                    <NavLink to={`/assets/${asset.asset?.assetId}`}>
                      <Card
                        sx={{
                          overflow: "visible",
                          paddingBottom: 2,
                          height: "300px",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="space-between"
                        >
                          <Box
                            sx={{
                              backgroundColor: colors.dozer.white,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <SoftAvatar
                              src={asset.asset?.typeInfo?.iconUrl}
                              alt={asset.asset?.typeInfo?.type}
                              size="md"
                              variant="rounded"
                              sx={{ margin: "auto", marginTop: -3, marginBottom: 1 }}
                            />

                            <Typography
                              variant="h4"
                              fontWeight="medium"
                              color={colors.dozer.gray.dark}
                              marginBottom={1}
                              xs={12}
                              textAlign="center"
                            >
                              {asset.asset?.nickName}
                            </Typography>

                            <Typography
                              variant="caption"
                              textTransform="uppercase"
                              fontWeight="light"
                              color={colors.dozer.gray.medium}
                              marginBottom={1}
                              textAlign="center"
                            >
                              {asset.asset?.year} {asset.asset?.model} {asset.asset?.typeInfo?.type}
                            </Typography>

                            <Chip
                              label={asset.asset.isActive ? "ACTIVE" : "IDLE"}
                              color={asset.asset.isActive ? "active" : "idle"}
                              sx={{
                                "& .MuiChip-label": {
                                  fontWeight: typography.fontWeightMedium,
                                },
                                mb: -2,
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              marginTop: 4,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                variant="button"
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.dozer.gray.light}
                                sx={{ width: "100%" }}
                              >
                                Active Time
                              </Typography>

                              <Typography
                                variant="h2"
                                color={colors.dozer.gray.dark}
                                opacity={0.7}
                                fontWeight="regular"
                                lineHeight={1}
                              >
                                {getActiveTime(asset.activeWindows)}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                variant="button"
                                textTransform="uppercase"
                                fontWeight="bold"
                                color={colors.dozer.gray.light}
                                sx={{ width: "100%" }}
                              >
                                warnings today
                              </Typography>

                              <Typography
                                variant="h2"
                                color={colors.dozer.gray.dark}
                                opacity={0.7}
                                fontWeight="regular"
                                lineHeight={1}
                              >
                                {asset.numWarnings}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Card>
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Assets;
