import { Button } from "@mui/material";
import {
  VideoNextIcon,
  VideoPauseIcon,
  VideoPlayIcon,
  VideoPreviousIcon,
  VideoSkipBackIcon,
  VideoSkipForwardIcon,
} from "assets/images/icons/Icons";
import colors from "assets/theme/base/colors";
import { CameraPosition } from "data/models/models";

export type VideoControlsProps = {
  canPlay: boolean;
  canGoPrevious: boolean;
  canGoNext: boolean;
  canSkipBack: boolean;
  canSkipForward: boolean;
  isPlaying: boolean;
  hidden: boolean;
  position: CameraPosition;
  currentTime?: number;
  currentSegment?: number;
  previousOnClick?: (position: CameraPosition) => void;
  skipBackOnClick?: (position: CameraPosition) => void;
  pauseResumeOnClick?: (shouldPlay: boolean, position?: CameraPosition) => void;
  skipForwardOnCLick?: (position: CameraPosition) => void;
  nextOnClick?: (position: CameraPosition) => void;
};

const VideoControls = (videoControls: VideoControlsProps) => {
  return (
    <>
      <div
        style={{
          display: videoControls.hidden ? "none" : "flex",
          position: "absolute",
          bottom: "0",
          justifyContent: "space-between",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "1.5rem",
          paddingBottom: "1.5rem",
          borderRadius: "0.375rem",
          background: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Button
          onClick={(e) =>
            videoControls.canGoPrevious &&
            videoControls.previousOnClick &&
            videoControls.previousOnClick(videoControls.position)
          }
        >
          <VideoPreviousIcon
            fill={videoControls.canGoPrevious ? "#ffffff" : colors.dozer.gray.dark}
          />
        </Button>
        <Button
          onClick={(e) =>
            videoControls.canSkipBack &&
            videoControls.skipBackOnClick &&
            videoControls.skipBackOnClick(videoControls.position)
          }
        >
          <VideoSkipBackIcon
            fill={videoControls.canSkipBack ? "#ffffff" : colors.dozer.gray.dark}
          />
        </Button>
        <Button
          onClick={(e) =>
            videoControls.pauseResumeOnClick &&
            videoControls.pauseResumeOnClick(!videoControls.isPlaying)
          }
        >
          {videoControls.isPlaying ? (
            <VideoPauseIcon fill={"#ffffff"} />
          ) : (
            <VideoPlayIcon fill={"#ffffff"} />
          )}
        </Button>
        <Button
          onClick={(e) =>
            videoControls.canSkipForward &&
            videoControls.skipForwardOnCLick &&
            videoControls.skipForwardOnCLick(videoControls.position)
          }
        >
          <VideoSkipForwardIcon
            fill={videoControls.canSkipForward ? "#ffffff" : colors.dozer.gray.dark}
          />
        </Button>
        <Button
          onClick={(e) =>
            videoControls.canGoNext &&
            videoControls.nextOnClick &&
            videoControls.nextOnClick(videoControls.position)
          }
        >
          <VideoNextIcon fill={videoControls.canGoNext ? "#ffffff" : colors.dozer.gray.dark} />
        </Button>
      </div>
    </>
  );
};

export default VideoControls;
