/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Globe from "examples/Globe";

// Data
import { useContext, useEffect, useState } from "react";
import { DozerContext, DozerDispatchContext } from "context/dozer-context";
import { getDashboardData } from "data/api/user";
import DozerTable from "examples/Tables/DozerTable";
import DozerDashboardCard from "examples/Cards/DozerCard/DozerCard";
import { capitalize } from "lodash";
import DozerEquipmentTableCell from "examples/Tables/DozerTable/DozerEquipmentTableCell";
import DozerJobsiteTableCell from "examples/Tables/DozerTable/DozerJobsiteTableCell";
import DozerStatLabel from "components/DozerStatLabel";
import DozerTimelineItem from "examples/Timeline/DozerTimelineItem";
import { EventLevelToImage } from "assets/timelineIcons";

import { useAuth0 } from "@auth0/auth0-react";
import { mapEquipmentToRows } from "data/api/util";

function Dashboard() {
  const [isLoadingDashboard, setLoadingDashboard] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const dozerContext = useContext(DozerContext);
  const dozerDispatch = useContext(DozerDispatchContext);

  useEffect(() => {
    const loadDashboard = async () => {
      const dozerUser = dozerContext.user;

      if (dozerUser && dozerUser.organizationId) {
        const orgId = dozerUser.organizationId;
        let dashboardDataRes = null;

        try {
          dashboardDataRes = await getDashboardData(orgId);
        } catch (error) {
          // TODO: Later surface an error toast to the user.
          console.log("error in getDashboardData", error);
        }

        if (dashboardDataRes) {
          setDashboardData(dashboardDataRes);
          setLoadingDashboard(false);
        }
      }
    };

    loadDashboard();
  }, []);

  let activeEquipmentCard;

  const getEquipmentCardTextColor = () => {
    if (dashboardData?.activeEquipment?.length > 0) {
      return "success";
    } else {
      return "text";
    }
  };

  let idleEquipmentCard;
  if (dashboardData?.idleEquipment) {
    idleEquipmentCard = (
      <>
        <SoftTypography
          variant="subtitle2"
          color="text"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          {`idle equipment`}
        </SoftTypography>

        <SoftTypography
          variant="h1"
          color="text"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          {`${dashboardData.idleEquipment.length}`}
        </SoftTypography>
      </>
    );
  }

  let activeJobsitesCard;
  if (dashboardData?.activeJobsites) {
    activeJobsitesCard = (
      <>
        <SoftTypography
          variant="subtitle2"
          color="text"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          {`active jobsites`}
        </SoftTypography>

        <SoftTypography
          variant="h1"
          color="text"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          {`${dashboardData.activeJobsites.length}`}
        </SoftTypography>
      </>
    );
  }

  let numIncidentsCard;
  if (dashboardData) {
    numIncidentsCard = (
      <>
        <SoftTypography
          variant="subtitle2"
          color="text"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          {`incidents today`}
        </SoftTypography>

        <SoftTypography
          variant="h1"
          color="text"
          opacity={0.7}
          textTransform="uppercase"
          fontWeight="bold"
        >
          {`${dashboardData.incidentsToday}`}
        </SoftTypography>
      </>
    );
  }

  if (!dashboardData) {
    return null;
  }

  return (
    <DashboardLayout pageTitle="Dashboard">
      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <DozerDashboardCard sx={{ mb: 3, width: "100%", height: { xs: "auto", md: "500px" } }}>
              <Grid container p={3} spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Grid container direction="column" sx={{ width: "100%" }}>
                    <DozerStatLabel
                      statName="active equipment"
                      statValue={dashboardData.activeEquipment.length}
                      color={getEquipmentCardTextColor()}
                    />
                    {dashboardData?.activeEquipment && (
                      <DozerTable rows={mapEquipmentToRows(dashboardData?.activeEquipment, true)} />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <Grid container direction="column">
                    {idleEquipmentCard}
                    {dashboardData?.idleEquipment && (
                      <DozerTable rows={mapEquipmentToRows(dashboardData?.idleEquipment, false)} />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <Grid container direction="column" sx={{ width: "100%" }}>
                    {activeJobsitesCard}
                    {dashboardData?.activeJobsites && (
                      <DozerTable rows={mapJobsitesToRows(dashboardData?.activeJobsites)} />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <Grid container direction="column" sx={{ width: "100%" }}>
                    {numIncidentsCard}
                  </Grid>
                </Grid>
              </Grid>
            </DozerDashboardCard>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

function mapJobsitesToRows(jobsites) {
  return jobsites.map((row) => {
    const jobsiteCell = (
      <DozerJobsiteTableCell key={row.id} jobsiteName={row.name} jobsiteCity={row.address} />
    );

    return jobsiteCell;
  });
}

export default Dashboard;
