import TimelineList from "examples/Timeline/TimelineList";
import DozerTimelineItem from "examples/Timeline/DozerTimelineItem";
import { AssetEvent, AssetEventLevel, AssetEventType } from "data/models/models";
import { eventTitleToDescription, eventTypeToTitle } from "data/api/util";
import moment from "moment";
import Card from "@mui/material/Card";
import {
  CheckCircleIconOutline,
  AlertCircleIconOutline,
  AlertDecagramIconOutline,
  CloseIcon,
} from "assets/Icons";
import colors from "assets/theme/base/colors";
import { Grid, Button } from "@mui/material";

type Props = {
  timelineEvents: AssetEvent[];
  onVideoPlayer?: boolean;
  onClose?: () => void;
  onEventClick?: (event: AssetEvent) => void;
};

const EVENT_LEVELS = {
  NORMAL: AssetEventLevel.Normal,
  WARNING: AssetEventLevel.Warning,
  INCIDENT: AssetEventLevel.Incident,
};

const getEventColor = (level: AssetEventLevel) => {
  switch (level) {
    case EVENT_LEVELS.NORMAL:
      return "success";
    case EVENT_LEVELS.WARNING:
      return "warning";
    case EVENT_LEVELS.INCIDENT:
      return "error";
    default:
      return "success";
  }
};

const getEventIcon = (level: AssetEventLevel) => {
  switch (level) {
    case EVENT_LEVELS.NORMAL:
      return <CheckCircleIconOutline />;
    case EVENT_LEVELS.WARNING:
      return <AlertCircleIconOutline />;
    case EVENT_LEVELS.INCIDENT:
      return <AlertDecagramIconOutline />;
    default:
      return <CheckCircleIconOutline />;
  }
};

const ActivityFeed = ({
  timelineEvents,
  onVideoPlayer = false,
  onClose = undefined,
  onEventClick,
}: Props) => {
  return (
    <>
      {onVideoPlayer && (
        <Grid
          container
          style={{
            background: colors.dozer.gray.dark,
            paddingRight: "8px",
            height: "10%",
            borderBottom: "1px solid gray",
          }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button onClick={onClose}>
            <CloseIcon fill={colors.dozer.gray.medium} />
          </Button>
        </Grid>
      )}
      {timelineEvents?.length ? (
        <TimelineList
          cardSX={{
            height: onVideoPlayer ? "90%" : "100%",
            overflow: "auto",
            boxShadow: "none",
            background: onVideoPlayer ? colors.dozer.gray.dark : colors.white.main,
          }}
          dark={onVideoPlayer}
        >
          {timelineEvents.map((event) => (
            <DozerTimelineItem
              key={event.eventTimestamp}
              color={getEventColor(event.level)}
              icon={getEventIcon(event.level)}
              title={eventTypeToTitle(event.type) || event.type}
              dateTime={moment(event.eventTimestamp).format("MM/DD/YYYY h:mm:ss A")}
              description={eventTitleToDescription(event.title || "")}
              image={event.gifUrl}
              onClick={onEventClick ? () => onEventClick(event) : undefined}
            />
          ))}
        </TimelineList>
      ) : (
        <Card
          sx={{
            height: onVideoPlayer ? "90%" : "100%",
            overflow: "auto",
            boxShadow: "none",
            backgroundColor: onVideoPlayer ? colors.dozer.gray.dark : colors.white.main,
          }}
        >
          <div
            style={{
              color: onVideoPlayer ? colors.white.main : colors.dozer.gray.dark,
              textAlign: "center",
              paddingTop: "10px",
            }}
          >
            No events present.
          </div>
        </Card>
      )}
    </>
  );
};

export default ActivityFeed;
