import { RotatingSquare } from "react-loader-spinner";
import colors from "assets/theme/base/colors";

const LoadingSpinner = () => {
  return (
    <RotatingSquare
      visible={true}
      height="96"
      width="96"
      color={colors.dozer.darkYellow}
      // strokeWidth="5"
      // animationDuration="0.75"
      ariaLabel="blocks-loading"
      wrapperStyle={{ color: colors.dozer.darkYellow }}
      // wrapperClass=""
    />
  );
};

export default LoadingSpinner;
