export const ASSET_POSITION = {
  FRONT: "FRONT",
  FRONT_LEFT: "FRONT_LEFT",
  FRONT_RIGHT: "FRONT_RIGHT",
  REAR: "REAR",
  REAR_LEFT: "REAR_LEFT",
  REAR_RIGHT: "REAR_RIGHT",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  SPHERICAL_CAM: "SPHERICAL_CAM",
  BASE: "BASE",
  UNKNOWN: "UNKNOWN",
};

export const STREAM_TYPE = {
  VIEWER: "VIEWER",
};

export const ASSET_POSITION_USER_FACING = {
  FRONT: "Front",
  FRONT_LEFT: "Front Left",
  FRONT_RIGHT: "Front Right",
  REAR: "Rear",
  REAR_LEFT: "Rear Left",
  REAR_RIGHT: "Rear Right",
  LEFT: "Left",
  RIGHT: "Right",
  SPHERICAL_CAM: "Spherical Cam 360",
  SPHERICAL_180_CAM: "Spherical Cam 180",
  FIXED_FOV_CAM: "Fixed Field of View",
  BASE: "Base",
  UNKNOWN: "Unknown",
};
